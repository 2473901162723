import { render, staticRenderFns } from "./mobile-menu-button.vue?vue&type=template&id=80ad78a2&scoped=true&"
import script from "./mobile-menu-button.vue?vue&type=script&lang=js&"
export * from "./mobile-menu-button.vue?vue&type=script&lang=js&"
import style0 from "./mobile-menu-button.vue?vue&type=style&index=0&id=80ad78a2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80ad78a2",
  null
  
)

export default component.exports