var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.competition)?_c('div',{staticClass:"pedestal__wrapper"},_vm._l(([1, 0, 2]),function(athletePlace){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pedestalAthletes[athletePlace]),expression:"pedestalAthletes[athletePlace]"}],key:athletePlace,staticClass:"pedestalAthlete_wrapper"},[(_vm.pedestalAthletes[athletePlace])?_c('div',{staticClass:"pedestalAthleteImage__wrapper"},[(_vm.pedestalAthletes[athletePlace]['photo_url'])?_c('img',{staticClass:"pedestalAthlete__image",attrs:{"src":_vm.uploadsFolderUrl() +
          `${_vm.pedestalAthletes[athletePlace]['photo_url']}`,"alt":"img","loading":"lazy"}}):_c('athlete-photo-filler-icon',{staticClass:"athletePhotoFiller__icon",attrs:{"gender":_vm.pedestalAthletes[athletePlace]?.gender}}),_vm._l((_vm.pedestalAthletes[athletePlace]['regions']),function(region,idx){return _c('country-flag',{key:idx,staticClass:"countryFlag",style:({
          zIndex: -idx,
          transform: `translate(${4 * idx}px, ${6 * idx}px)`,
        }),attrs:{"is-region-flag":"true","country-code":_vm.pedestalAthletes[athletePlace].country_code,"region-code":_vm.getRegionCode(region),"width":"1.5rem"}})}),_c('div',{staticClass:"athleteMedal",style:({ backgroundColor: _vm.getMedalColor(athletePlace) })},[_c('span',[_vm._v(_vm._s(athletePlace + 1))])])],2):_vm._e(),(_vm.pedestalAthletes[athletePlace])?_c('div',{staticClass:"pedestalAthleteInfo__wrapper"},[(_vm.pedestalAthletes[athletePlace].rus_code)?_c('router-link',{staticClass:"athleteName__link",attrs:{"to":{
          name: 'athletePage',
          params: { athlete_code: _vm.pedestalAthletes[athletePlace].rus_code },
        }}},[_vm._v(" "+_vm._s(_vm.getAthleteName(_vm.pedestalAthletes[athletePlace]))+" ")]):_c('div',{staticClass:"athleteName"},[_vm._v(" "+_vm._s(_vm.getAthleteName(_vm.pedestalAthletes[athletePlace]))+" ")]),_c('div',{staticClass:"athleteRegions"},_vm._l((_vm.pedestalAthletes[athletePlace].regions),function(region){return _c('span',{key:region,staticClass:"region__item"},[_vm._v(" "+_vm._s(region)+" ")])}),0)],1):_vm._e()])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }