<template>
  <div class="organizationsPage__wrapper">
    <search mode="organizations"></search>

    <div class="organizationsList__wrapper">
      <div class="organizationsList">
        <router-link
          v-for="(organization, idx) in organizations"
          :key="organization._id"
          :to="'/organizations/' + organization._id"
        >
          <div
            :class="[
              'organizationsList__item__wrapper',
              idx % 2 === 0 && 'isEven',
            ]"
          >
            <div class="organizationImage__wrapper">
              <img
                v-if="organization['logo_url']"
                class="organizationImage"
                :src="uploadsFolderUrl() + `${organization['logo_url']}`"
                alt="img"
                loading="lazy"
              />
              <div v-else class="imageFiller">
                <competition-image-filler-icon
                  class="imageFiller__icon"
                ></competition-image-filler-icon>
              </div>
            </div>
            <div class="organizationInfo__top">
              <span class="organizationInfo__title">
                {{ organization.title }}
              </span>

              <span class="organizationInfo__sport">
                <b>{{ organization.sport }}</b>

                <country-flag
                  class="countryFlag"
                  :country-code="getCountryCode(organization['country'])"
                  height="1rem"
                ></country-flag>
              </span>
            </div>

            <div class="organizationInfo__bottom">
              <div
                class="organizationInfo__region"
                v-if="organization['region']"
              >
                <country-flag
                  class="countryFlag"
                  is-region-flag="true"
                  :country-code="getCountryCode(organization.country)"
                  :region-code="getRegionCode(organization.region)"
                  height="1.25rem"
                ></country-flag>
                {{ organization["region"] }}
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { databaseUrl, uploadsFolderUrl } from "@/store/constants";
import { mdiAccount } from "@mdi/js";
import CountryFlag from "@/components/ui-components/country-flag.vue";
import Search from "@/components/ui-components/search/index.vue";
import { getDisciplineCode } from "@/store/data/sports";
import { getRegionCode } from "@/store/data/russia-regions";
import { getCountryCode } from "@/store/data/countries";
import CompetitionImageFillerIcon from "@/assets/svg/competitionImageFiller-icon.vue";

export default {
  name: "organizationsPage",
  components: {
    CompetitionImageFillerIcon,
    Search,
    CountryFlag,
  },
  methods: {
    getCountryCode,
    getRegionCode,
    getDisciplineCode,
    uploadsFolderUrl() {
      return uploadsFolderUrl;
    },
  },
  mounted() {
    axios.get(databaseUrl + "/organizations").then((response) => {
      if (response.status === 200) {
        this.organizations = [...response.data["organizations"]];
      }
    });
  },
  data() {
    return {
      athleteIcon: mdiAccount,
      organizations: [],
    };
  },
};
</script>

<style scoped lang="scss">
.organizationsPage__wrapper {
  flex: 1 1 0;
  display: flex;
  flex-wrap: nowrap;
  max-width: var(--desktop-small);
  width: 100%;
  overflow-y: auto;
  margin: 0 auto;
  padding: 2rem 2rem 8px;

  .organizationsList__wrapper {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;

    background-color: var(--background--card);
    backdrop-filter: blur(4px);
    border-radius: 4px;

    .organizationsList {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;

      .organizationsList__item__wrapper {
        display: grid;
        grid-template-areas:
          "image top"
          "image bottom";
        grid-template-columns: 80px auto;
        grid-gap: 0.5rem 1rem;

        &.isEven {
          background-color: var(--background--card-secondary);
        }
        &:hover {
          background-color: var(--background--card-hover);
        }
        .organizationImage__wrapper {
          position: relative;
          isolation: isolate;
          grid-area: image;
          align-self: start;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          aspect-ratio: 1;
          padding: 0.75rem;

          .organizationImage {
            max-height: 100%;
            max-width: 100%;
          }
          .imageFiller {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            height: 100%;
            width: 100%;
            color: var(--text-muted);

            .imageFiller__icon {
              flex: 1 1 0;
            }
          }

          @media screen and (max-width: 1440px) {
            width: 64px;
          }
          @media screen and (max-width: 1200px) {
            width: 50px;
          }
          @media screen and (max-width: 900px) {
            width: 46px;
          }
        }
        .organizationInfo__top {
          grid-area: top;
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;
          color: var(--text-default);

          .organizationInfo__title {
            position: relative;
            padding: 4px;
            font-size: 1.25rem;
            font-weight: bold;

            .athleteInfo__icon {
              margin-left: 8px;
            }
          }
          .organizationInfo__sport {
            display: flex;
            align-items: center;
            margin-left: auto;
            padding: 0.5rem 1rem;
            color: var(--text-card-contrast);
            background-color: var(--text-default);
            border-bottom-left-radius: 2px;
            line-height: 1;

            .countryFlag {
              margin-left: 0.5rem;
              border: 1px solid var(--text-hovered);
            }
          }
          @media screen and (max-width: 1200px) {
            .organizationInfo__title {
              flex: 1 1 auto;
            }
            .organizationInfo__sport {
              margin: 0;
            }
          }
        }
        .organizationInfo__bottom {
          margin-top: auto;
          padding: 0.5rem;

          .organizationInfo__region {
            flex: 0 0 auto;
            display: flex;
            align-items: center;

            .countryFlag {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}
</style>
