import Vue from "vue";
import Vuex from "vuex";

import events from "@/store/modules/events";
import menu from "@/store/modules/menu";
import authorization from "@/store/modules/authorization";
// import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    authorization,
    menu,
    events,
  },
});
