<template>
  <div class="userPage__wrapper">
    <div class="userInfo__wrapper">
      <div class="username__wrapper">
        <span>Пользователь:&nbsp;</span>
        <b>{{ userData.username }}</b>
      </div>
      <div class="userRole__wrapper">
        <span>Ваша роль:&nbsp;</span>
        <b>{{ translateRole(userData.role) }}</b>
      </div>

      <div class="adminPages__wrapper">
        <router-link class="adminPage__link" :to="{ name: 'createEventPage' }">
          Создать событие
        </router-link>
        <router-link
          class="adminPage__link"
          :to="{ name: 'createAthletePage' }"
        >
          Создать спортсмена
        </router-link>
        <router-link class="adminPage__link" :to="{ name: 'createJuryPage' }">
          Создать судью
        </router-link>
        <router-link
          class="adminPage__link"
          :to="{ name: 'createTrainerPage' }"
        >
          Создать тренера
        </router-link>
        <router-link
          class="adminPage__link"
          :to="{ name: 'createOrganizationPage' }"
        >
          Создать организацию
        </router-link>
        <router-link
          class="adminPage__link"
          :to="{ name: 'createSeminarPage' }"
        >
          Создать семинар
        </router-link>
      </div>

      <div class="userPage__actions">
        <v-btn
          @click.prevent="logout"
          class="userPage__actions-exit"
          color="var(--message-error)"
          text
        >
          Выйти
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "userPage",
  methods: {
    ...mapActions("authorization", {
      exitAccount: "EXIT_ACCOUNT",
    }),

    logout() {
      this.exitAccount();
    },
    translateRole(role) {
      const rolesMap = {
        admin: "Администратор",
        user: "Пользователь",
      };

      return rolesMap[role] || role;
    },
  },
  computed: {
    ...mapGetters("authorization", {
      userData: "getUserData",
    }),
  },
  beforeUpdate() {
    if (!this.userData.token) this.$router.push({ name: "auth" });
  },
};
</script>

<style scoped lang="scss">
.userPage__wrapper {
  position: relative;
  margin: 0 auto;
  padding: 1rem 2rem;
  max-width: 720px;
  width: 100%;

  .userInfo__wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;

    background-color: var(--background--card);
    backdrop-filter: blur(4px);
    border-radius: 6px;
    font-size: 1.2rem;

    .adminPages__wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      margin-top: 2rem;

      .adminPage__link {
        padding: 4px 1rem;
        color: var(--text-default);
        background-color: var(--background--card-hover);
        border: 1px solid var(--text-default);
        border-radius: 2px;
        font-size: 0.95rem;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        transition: color 92ms, background-color 92ms;

        &:hover {
          color: var(--text-hovered);
          background-color: var(--background--card-secondary);
        }
      }
    }

    .userPage__actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;

      .userPage__actions-exit {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
}
</style>
