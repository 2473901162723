<template>
  <div class="authorizationPage__wrapper">
    <form class="authorizationForm" @submit.prevent="authorizeUser">
      <div class="authorizationForm__inputWrapper">
        <label for="login">Логин:&nbsp;</label>
        <input v-model="authorizationData.username" id="login" type="text" />
      </div>

      <div class="authorizationForm__inputWrapper">
        <label for="pwd">Пароль:&nbsp;</label>
        <input
          v-model="authorizationData.password"
          id="pwd"
          :type="showPassword ? 'text' : 'password'"
        />

        <v-btn
          @click="showPassword = !showPassword"
          class="showPassword__button"
          :color="showPassword ? '#2c3e50' : '#b6b9c1'"
          x-small
          icon
        >
          <v-icon small>{{ passwordVisibilityIcon }}</v-icon>
        </v-btn>
      </div>

      <div class="authorizeUser__button__wrapper">
        <v-btn class="authorizeUser__button" type="submit" color="#029fe2" text>
          Войти
        </v-btn>
      </div>
    </form>

    <message-container
      :messages="messages"
      :errors="errors"
    ></message-container>
  </div>
</template>

<script>
import { mdiEye } from "@mdi/js";
import axios from "axios";
import { databaseUrl } from "@/store/constants";
import { mapActions, mapGetters } from "vuex";
import MessageContainer from "@/components/ui-components/message-container.vue";

export default {
  name: "authPage",
  components: { MessageContainer },
  mounted() {
    if (this.userData.token) {
      this.$router.push({ name: "user-page" });
    }
  },
  methods: {
    ...mapActions("authorization", {
      updateUserData: "CHECK_STORED_DATA",
    }),

    addError(errMessage) {
      if (!this.errors.some((err) => err === errMessage))
        this.errors.push(errMessage);

      setTimeout(() => {
        const errIndex = this.errors.indexOf(errMessage).toString();

        if (errIndex) this.errors.splice(Number(errIndex), 1);
      }, 2048);
    },
    addMessage(message) {
      if (!this.messages.some((msg) => msg === message))
        this.messages.push(message);

      setTimeout(() => {
        const msgIdx = this.messages.indexOf(message).toString();

        if (msgIdx) this.messages.splice(Number(msgIdx), 1);
      }, 2048);
    },
    authorizeUser() {
      if (!this.authorizationData.username) {
        this.addError("Введите логин");
        return;
      }
      if (!this.authorizationData.password) {
        this.addError("Введите пароль");
        return;
      }

      axios
        .post(databaseUrl + "/auth/login", this.authorizationData)
        .then((response) => {
          if (response.status === 200) {
            const userData = {
              ...this.authorizationData,
              token: response.data.token,
              role: response.data.role,
            };
            localStorage.setItem("authorizationData", JSON.stringify(userData));
            this.addMessage(`Добрый день, ${userData.username}!`);

            setTimeout(() => {
              if (this.$route.name === "results") return;

              this.$router.push({ name: "results" });
              this.updateUserData();
            }, 1536);
          }
        })
        .catch((err) => {
          this.addError(err.response.data.message);
        });
    },
  },
  data() {
    return {
      authorizationData: {
        username: "",
        password: "",
      },
      errors: [],
      messages: [],
      showPassword: false,

      passwordVisibilityIcon: mdiEye,
    };
  },
  computed: {
    ...mapGetters("authorization", {
      userData: "getUserData",
    }),
  },
};
</script>

<style scoped lang="scss">
.authorizationPage__wrapper {
  z-index: 1;
  isolation: isolate;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 32px;

  .authorizationForm {
    padding: 1rem 2rem;
    background-color: var(--background--card);
    backdrop-filter: blur(8px);
    border-radius: 4px;

    .authorizationForm__inputWrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        width: 6rem;
        font-weight: bold;
      }
      input {
        width: 12rem;
        padding: 3px 6px;
        border-radius: 2px;
        color: var(--text-default);
        background-color: var(--background--card-secondary);

        &:focus {
          outline: solid var(--accent) 1px;
        }
      }
      .showPassword__button {
        position: absolute;
        right: 4px;
        margin: auto;
      }
    }

    .authorizeUser__button__wrapper {
      display: flex;

      .authorizeUser__button {
        margin-left: auto;
        font-weight: bold;
      }
    }
  }
}
</style>
