<template>
  <div class="allAthletesPage__wrapper">
    <search
      @search-loading="setLoadingState"
      @search-results-loaded="showSearchResults"
      mode="athletes"
    ></search>

    <div class="allAthletesPage__athletesList__wrapper">
      <div class="allAthletesPage__athletesList">
        <athlete-list-item
          v-for="(athlete, idx) in athletesList"
          :key="athlete._id"
          :athlete="athlete"
          :idx="idx"
        ></athlete-list-item>

        <span class="emptyEvents" v-if="athletesList === 0 && !loading">
          Участники не найдены
        </span>

        <loader-spinner
          v-if="loading"
          class="loading__spinner"
        ></loader-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { databaseUrl, uploadsFolderUrl } from "@/store/constants";
import { mdiAccount } from "@mdi/js";
import Search from "@/components/ui-components/search/index.vue";
import { getDisciplineCode } from "@/store/data/sports";
import { getRegionCode } from "@/store/data/russia-regions";
import { formatBirthDate, getAthleteName } from "@/utils/data-formaters";
import AthleteListItem from "@/components/athletes/athlete-listItem.vue";
import LoaderSpinner from "@/components/ui-components/loader-spinner.vue";

export default {
  name: "allAthletesPage",
  components: { LoaderSpinner, AthleteListItem, Search },
  data() {
    return {
      athleteIcon: mdiAccount,
      athletesList: [],

      loading: false,
    };
  },
  computed: {
    uploadsFolderUrl() {
      return uploadsFolderUrl;
    },
  },
  methods: {
    getAthleteName,
    formatBirthDate,
    getRegionCode,
    getDisciplineCode,

    async loadAthletes() {
      this.loading = true;

      try {
        const response = await axios.get(databaseUrl + "/athletes");

        if (response.status === 200) {
          const athletesList = response.data["athletes"];
          this.athletesList = athletesList;
        }
      } catch (e) {
        if (e) {
          console.error(
            "Ошибка загрузки спортсменов:",
            e.response?.data?.message || e.message
          );
        }
      } finally {
        this.loading = false;
      }
    },

    setLoadingState(state) {
      this.loading = state;
    },
    showSearchResults(searchResults) {
      this.athletesList = searchResults;
    },
  },

  mounted() {
    this.loadAthletes();
  },
};
</script>

<style scoped lang="scss">
.allAthletesPage__wrapper {
  flex: 1 1 0;
  display: flex;
  flex-wrap: nowrap;
  max-width: var(--desktop-small);
  width: 100%;
  overflow-y: auto;
  margin: 0 auto;
  padding: 2rem 2rem 8px;

  .allAthletesPage__athletesList__wrapper {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;

    background-color: var(--background--card);
    backdrop-filter: blur(4px);
    border-radius: 4px;

    .allAthletesPage__athletesList {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
