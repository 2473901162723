<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <circle r="8" cx="12" cy="12" fill="transparent" />
    <path
      d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2M5.5 8.5H7c.36 0 .5.16.64.57c.72 2.1 1.93 3.93 2.43 3.93c.19 0 .28-.08.28-.55v-2.17c-.06-1-.59-1.09-.59-1.44c0-.17.14-.34.38-.34h2.31c.32 0 .42.17.42.54v2.92c0 .31.13.42.23.42c.19 0 .35-.11.69-.45c1.06-1.19 1.81-3.01 1.81-3.01c.1-.22.27-.42.64-.42h1.47c.45 0 .55.23.45.54c-.19.86-1.98 3.39-1.98 3.39c-.18.25-.22.37 0 .66c.15.21.67.65 1.01 1.06c.64.71 1.11 1.31 1.25 1.72c.12.42-.09.63-.51.63h-1.48c-.56 0-.72-.45-1.72-1.45c-.88-.84-1.23-.95-1.47-.95c-.3 0-.39.08-.39.51v1.32c0 .36-.11.57-1.05.57c-1.56 0-3.28-.95-4.49-2.7C5.5 11.24 5 9.31 5 8.92c0-.22.08-.42.5-.42z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "socials-vk-icon",
};
</script>

<style scoped lang="scss">
svg {
  * {
    transition: fill 92ms;
  }
  &:hover {
    circle {
      fill: white;
    }
    path {
      fill: #3f64ad;
    }
  }
}
</style>
