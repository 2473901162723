<template>
  <form class="search_wrapper" @submit.prevent="search">
    <div class="search_header">
      <div class="federationLogo__wrapper">
        <img
          class="federationLogo__image"
          src="../../../assets/logo/FFR_logo.png"
          alt="FFR_LOGO"
        />
      </div>

      <div class="federationInfo__wrapper">
        <div class="federationInfo__title">Федерация фристайла России</div>

        <a
          class="federationInfo__link"
          href="https://ffr-ski.ru"
          target="_blank"
        >
          <web-icon class="link__icon"></web-icon>
          <span>ffr-ski.ru</span>
        </a>

        <div class="federationInfo__socials"></div>
      </div>
    </div>

    <div class="search_body">
      <div
        v-for="(_, key) in getSearchFields"
        :key="key"
        class="searchControl__wrapper"
      >
        <label :for="key" class="searchControl__label">
          {{ translateField(key) }}
        </label>

        <sport-input
          v-if="key === 'sport'"
          v-model="getSearchFields[key]"
        ></sport-input>
        <discipline-input
          v-else-if="key === 'discipline'"
          v-model="getSearchFields[key]"
        ></discipline-input>
        <gender-input
          v-else-if="key === 'gender'"
          v-model="getSearchFields[key]"
        ></gender-input>
        <athlete-category-input
          v-else-if="key === 'category'"
          v-model="getSearchFields[key]"
        ></athlete-category-input>
        <jury-category-input
          v-else-if="key === 'jury_category'"
          v-model="getSearchFields[key]"
        ></jury-category-input>

        <input
          v-else
          v-model="getSearchFields[key]"
          :id="key"
          :name="key"
          class="searchControl__input"
        />
      </div>
    </div>

    <div class="search_actions">
      <v-btn type="submit" class="search__button" color="var(--text-contrast)">
        Поиск
      </v-btn>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { databaseUrl } from "@/store/constants";
import WebIcon from "@/assets/icons/web-icon.vue";
import { translateField } from "@/utils/formFields-translator";
import DisciplineInput from "@/components/ui-components/search/search-inputs/discipline-input.vue";
import SportInput from "@/components/ui-components/search/search-inputs/sport-input.vue";
import GenderInput from "@/components/ui-components/search/search-inputs/gender-input.vue";
import AthleteCategoryInput from "@/components/ui-components/search/search-inputs/athlete-category-input.vue";
import JuryCategoryInput from "@/components/ui-components/search/search-inputs/jury-category-input.vue";

export default {
  name: "search",
  components: {
    JuryCategoryInput,
    AthleteCategoryInput,
    GenderInput,
    SportInput,
    DisciplineInput,
    WebIcon,
  },
  props: ["mode"],
  methods: {
    translateField,
    async search() {
      this.$emit("search-loading", true);
      try {
        const searchParams = new URLSearchParams();
        for (const [key, value] of Object.entries(this.getSearchFields)) {
          if (value) {
            searchParams.append(key, value);
          }
        }
        const queryString = searchParams.toString();

        const response = await axios.get(
          `${databaseUrl}/${this.mode}/find${
            queryString ? "?" + queryString : ""
          }`
        );
        if (response.status === 200) {
          this.$emit("search-results-loaded", response.data[this.mode]);
          this.$emit("search-loading", false);
        }
      } catch (e) {
        if (e) {
          console.error(
            "Error fetching events:",
            e.response?.data?.message || e.message
          );
          this.$emit("search-loading", false);
        }
      }
    },
  },
  data() {
    return {
      searchFilters: {
        events: {
          title: "",
          sport: "",
          discipline: "",
          season: "",
          date: "",
          location: "",
          calendar_code: "",
        },
        athletes: {
          rus_code: "",
          sport: "",
          discipline: "",
          name: "",
          gender: "",
          year: "",
          category: "",
          region: "",
        },
        jury: {
          jury_code: "",
          name: "",
          sport: "",
          discipline: "",
          gender: "",
          age: "",
          jury_category: "",
          region: "",
        },
        trainers: {
          trainer_id: "",
          fullname: "",
          sport: "",
          discipline: "",
          gender: "",
          region: "",
        },
        organizations: {
          sport: "",
          title: "",
          region: "",
        },
        seminars: {
          sport: "",
          discipline: "",
          season: "",
          level: "",
          location: "",
          date: "",
        },
      },
    };
  },
  computed: {
    getSearchFields() {
      return this.searchFilters[this.mode] || {};
    },
  },
};
</script>

<style scoped lang="scss">
.search_wrapper {
  position: relative;
  align-self: flex-start;
  width: 368px;
  margin-right: 16px;
  padding: 12px;
  background-color: var(--background--card);
  backdrop-filter: blur(8px);
  border-radius: 4px;

  @media screen and (max-width: 900px) {
    display: none;
  }
  .search_header {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 8px;

    .federationLogo__wrapper {
      max-width: 64px;

      .federationLogo__image {
        max-height: 100%;
        max-width: 100%;
      }
    }
    .federationInfo__wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-left: 1rem;
      padding: 8px 0;

      .federationInfo__title {
        font-weight: bold;
      }

      .federationInfo__link {
        display: flex;
        flex-wrap: nowrap;
        color: var(--text-default);

        &:visited {
          color: var(--text-default);
        }
        &:hover {
          text-decoration: underline;
        }
        .link__icon {
          height: 18px;
          margin-right: 4px;
        }
      }

      .federationInfo__socials {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .search_body {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .searchControl__wrapper {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: 4px;
      padding-bottom: 4px;
      border-bottom: 1px solid var(--background--card-hover);
      transition: border-bottom-color 92ms;

      &:focus-within {
        border-bottom: 1px solid var(--text-muted);
      }
      .searchControl__label {
        flex: 2 1 0;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        user-select: none;

        &:hover {
          overflow: visible;
        }
      }
      .searchControl__input {
        min-width: 0;
        flex: 4 0 0;
        margin-left: 8px;
        padding: 4px 8px;

        color: var(--text-default);
        background-color: var(--background--card-secondary);
        border-radius: 2px;
        outline: transparent;
        transition: background-color 92ms;

        &:focus {
          background-color: var(--background--card-hover);
        }
      }
    }
  }

  .search_actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    .search__button {
      padding: 3px 1rem !important;
      font-size: 1rem;
      letter-spacing: 0;
      text-transform: capitalize;
    }
  }
}
</style>
