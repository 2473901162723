var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"seminarsControl__wrapper"},[_c('div',{staticClass:"seminarParticipants__select__wrapper"},[_c('div',{staticClass:"databasePersonal__wrapper"},[_c('div',{staticClass:"dbPerson__list__wrapper"},[_c('div',{staticClass:"personList__title"},[_vm._v("Судьи")]),_c('div',{staticClass:"dbPerson__list"},_vm._l((_vm.getFilteredJuryList),function(jury){return _c('div',{key:jury._id,class:[
              'dbPerson__item',
              _vm.editingPerson === jury.jury_code && 'editingPerson',
            ]},[_c('div',{staticClass:"dbPerson__item__name",on:{"click":function($event){return _vm.editPerson(jury.jury_code)}}},[_vm._v(" "+_vm._s(`${jury.lastname ? jury.lastname : ""} ${jury.name}`)+" ")]),_c('div',{staticClass:"dbPerson__item__menu"},[_c('label',{attrs:{"for":jury._id + '_role'}},[_vm._v("Роль")]),_c('input',{attrs:{"id":jury._id + '_role',"type":"text","placeholder":"Введите роль"}}),_c('v-btn',{attrs:{"color":"var(--accent)","small":"","text":""},on:{"click":function($event){return _vm.addDbPerson(jury)}}},[_vm._v(" Добавить ")])],1)])}),0)]),_c('div',{staticClass:"dbPerson__list__wrapper"},[_c('div',{staticClass:"personList__title"},[_vm._v("Тренеры")]),_c('div',{staticClass:"dbPerson__list"},_vm._l((_vm.getFilteredTrainersList),function(trainer){return _c('div',{key:trainer._id,class:[
              'dbPerson__item',
              _vm.editingPerson === trainer.trainer_id && 'editingPerson',
            ]},[_c('div',{staticClass:"dbPerson__item__name",on:{"click":function($event){return _vm.editPerson(trainer.trainer_id)}}},[_vm._v(" "+_vm._s(trainer.fullname)+" ")]),_c('div',{staticClass:"dbPerson__item__menu"},[_c('label',{attrs:{"for":trainer._id + '_role'}},[_vm._v("Роль")]),_c('input',{attrs:{"id":trainer._id + '_role',"type":"text","placeholder":"Введите роль"}}),_c('v-btn',{attrs:{"color":"var(--accent)","small":"","text":""},on:{"click":function($event){return _vm.addDbPerson(trainer)}}},[_vm._v(" Добавить ")])],1)])}),0)])]),_c('div',{staticClass:"addNewPerson__wrapper"},[_vm._m(0),_vm._m(1),_c('v-btn',{attrs:{"color":"var(--accent)","text":""},on:{"click":_vm.addPerson}},[_vm._v(" Добавить ")])],1),_c('div',{staticClass:"personal__list__wrapper"},[_c('div',{staticClass:"personal__list__title"},[_vm._v("Участники семинара")]),_c('div',{staticClass:"personal__list"},_vm._l((_vm.personal),function(person,idx){return _c('div',{key:idx,staticClass:"personal__item__wrapper"},[_c('span',[_vm._v(_vm._s(person.fullname)+" - ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(person.role),expression:"person.role"}],attrs:{"type":"text"},domProps:{"value":(person.role)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(person, "role", $event.target.value)}}}),_c('span',{staticClass:"removeParticipant__button",on:{"click":function($event){return _vm.removeParticipant(idx)}}},[_vm._v(" удалить ")])])}),0),_c('div',{staticClass:"personal__list__menu"},[_c('v-btn',{attrs:{"color":"var(--accent)","text":""},on:{"click":_vm.updatePersonal}},[_vm._v(" Сохранить ")])],1)]),_c('message-container',{attrs:{"errors":_vm.errors}}),_c('v-btn',{staticClass:"closeButton",attrs:{"color":"var(--message-error)"},on:{"click":_vm.closeControl}},[_vm._v(" Закрыть ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"newPerson__control__wrapper"},[_c('label',{attrs:{"for":"role"}},[_vm._v("Роль участника")]),_c('input',{attrs:{"id":"role","type":"text","placeholder":"Введите роль"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"newPerson__control__wrapper"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Имя участника")]),_c('input',{attrs:{"id":"name","type":"text","placeholder":"Введите имя"}})])
}]

export { render, staticRenderFns }