<template>
  <div class="seminarsControl__wrapper">
    <div class="seminarParticipants__select__wrapper">
      <div class="databasePersonal__wrapper">
        <div class="dbPerson__list__wrapper">
          <div class="personList__title">Судьи</div>
          <div class="dbPerson__list">
            <div
              :class="[
                'dbPerson__item',
                editingPerson === jury.jury_code && 'editingPerson',
              ]"
              v-for="jury in getFilteredJuryList"
              :key="jury._id"
            >
              <div
                @click="editPerson(jury.jury_code)"
                class="dbPerson__item__name"
              >
                {{ `${jury.lastname ? jury.lastname : ""} ${jury.name}` }}
              </div>
              <div class="dbPerson__item__menu">
                <label :for="jury._id + '_role'">Роль</label>
                <input
                  :id="jury._id + '_role'"
                  type="text"
                  placeholder="Введите роль"
                />

                <v-btn
                  @click="addDbPerson(jury)"
                  color="var(--accent)"
                  small
                  text
                >
                  Добавить
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="dbPerson__list__wrapper">
          <div class="personList__title">Тренеры</div>
          <div class="dbPerson__list">
            <div
              :class="[
                'dbPerson__item',
                editingPerson === trainer.trainer_id && 'editingPerson',
              ]"
              v-for="trainer in getFilteredTrainersList"
              :key="trainer._id"
            >
              <div
                @click="editPerson(trainer.trainer_id)"
                class="dbPerson__item__name"
              >
                {{ trainer.fullname }}
              </div>
              <div class="dbPerson__item__menu">
                <label :for="trainer._id + '_role'">Роль</label>
                <input
                  :id="trainer._id + '_role'"
                  type="text"
                  placeholder="Введите роль"
                />

                <v-btn
                  @click="addDbPerson(trainer)"
                  color="var(--accent)"
                  small
                  text
                >
                  Добавить
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="addNewPerson__wrapper">
        <div class="newPerson__control__wrapper">
          <label for="role">Роль участника</label>
          <input id="role" type="text" placeholder="Введите роль" />
        </div>
        <div class="newPerson__control__wrapper">
          <label for="name">Имя участника</label>
          <input id="name" type="text" placeholder="Введите имя" />
        </div>
        <v-btn @click="addPerson" color="var(--accent)" text> Добавить </v-btn>
      </div>
      <div class="personal__list__wrapper">
        <div class="personal__list__title">Участники семинара</div>
        <div class="personal__list">
          <div
            class="personal__item__wrapper"
            v-for="(person, idx) in personal"
            :key="idx"
          >
            <span>{{ person.fullname }}&nbsp;-&nbsp;</span>
            <input type="text" v-model="person.role" />

            <span
              @click="removeParticipant(idx)"
              class="removeParticipant__button"
            >
              удалить
            </span>
          </div>
        </div>
        <div class="personal__list__menu">
          <v-btn @click="updatePersonal" color="var(--accent)" text>
            Сохранить
          </v-btn>
        </div>
      </div>

      <message-container :errors="errors"></message-container>

      <v-btn
        class="closeButton"
        @click="closeControl"
        color="var(--message-error)"
      >
        Закрыть
      </v-btn>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { databaseUrl } from "@/store/constants";
import { mapGetters } from "vuex";
import MessageContainer from "@/components/ui-components/message-container.vue";

export default {
  name: "seminar-participants-control",
  components: { MessageContainer },
  props: {
    seminar: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      jury: [],
      trainers: [],

      editingPerson: null,
      personal: [],

      errors: [],
    };
  },
  computed: {
    ...mapGetters("authorization", {
      userData: "getUserData",
    }),
    getFilteredJuryList() {
      return this.jury.filter((jury) =>
        this.personal.every((person) => person.code !== jury.jury_code)
      );
    },
    getFilteredTrainersList() {
      return this.trainers.filter((trainer) =>
        this.personal.every((person) => person.code !== trainer.trainer_id)
      );
    },
  },
  methods: {
    async getJuryList() {
      try {
        const response = await axios.get(`${databaseUrl}/jury`);
        if (response.status === 200) {
          this.jury = response.data.juryList;
        }
      } catch (error) {
        if (error) {
          console.log(error?.data?.message);
        }
      }
    },
    async getTrainersList() {
      try {
        const response = await axios.get(`${databaseUrl}/trainers`);
        if (response.status === 200) {
          this.trainers = response.data.trainers;
        }
      } catch (error) {
        if (error) {
          console.log(error?.data?.message);
        }
      }
    },
    editPerson(code) {
      if (code === this.editingPerson) {
        this.editingPerson = null;
        return;
      }
      this.editingPerson = code;
    },
    addDbPerson(person) {
      const roleInput = document.getElementById(person._id + "_role");
      const personName = person.fullname
        ? person.fullname
        : `${person.lastname ? person.lastname + " " : ""} ${person.name}`;

      const personObj = {
        code: person.jury_code || person.trainer_id || "",
        role: roleInput.value,
        fullname: personName,
      };

      this.personal.push(personObj);
      roleInput.value = "";
    },
    addPerson() {
      const roleInput = document.getElementById("role"),
        nameInput = document.getElementById("name");

      const person = {
        code: "",
        role: roleInput.value,
        fullname: nameInput.value,
      };

      this.personal.push(person);
      roleInput.value = "";
      nameInput.value = "";
    },
    removeParticipant(idx) {
      this.personal.splice(idx, 1);
    },

    async updatePersonal() {
      try {
        const response = await axios.patch(
          `${databaseUrl}/seminars/${this.seminar._id}/participants`,
          { participants: JSON.stringify(this.personal) },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${this.userData.token}`,
            },
          }
        );
        if (response.status === 200) {
          this.$emit("close-participants-control");
        }
      } catch (error) {
        if (error) {
          console.log(error?.response?.data);
          this.errors.push(error?.response?.data?.message);
        }
      }
    },

    closeControl() {
      this.$emit("close-participants-control");
    },
  },

  mounted() {
    this.getJuryList();
    this.getTrainersList();

    this.personal = this.seminar.participants;
  },
};
</script>

<style scoped lang="scss">
.seminarsControl__wrapper {
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--background--card-secondary);
  backdrop-filter: blur(8px);

  .seminarParticipants__select__wrapper {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: var(--desktop-small);
    width: 100%;
    margin: 2rem auto;

    .databasePersonal__wrapper {
      flex: 5 1 0;
      display: flex;
      flex-wrap: wrap;
      gap: 1.75rem;

      .dbPerson__list__wrapper {
        flex: 1 1 300px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 0.5rem 1rem;

        background-color: var(--background--card);
        border-radius: 4px;

        .personList__title {
          flex: 0 0 auto;
          padding: 0 0.5rem 0.75rem;
          font-size: 1.15rem;
        }
        .dbPerson__list {
          flex: 1 1 0;
          display: flex;
          flex-direction: column;
          gap: 6px;
          overflow-y: auto;

          .dbPerson__item {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            padding: 0.25rem;
            border-radius: 4px;

            .dbPerson__item__name {
              flex: 0 0 auto;
              cursor: pointer;

              &:hover {
                color: var(--text-hovered);
              }
            }
            .dbPerson__item__menu {
              display: none;
              flex: 0 0 auto;
              align-items: center;
              margin-top: 0.5rem;
              font-size: 0.85rem;

              label {
                font-weight: bold;
                margin-right: 0.5rem;
              }
              input {
                padding: 0.1rem 0.5rem;
                background-color: var(--background--card-secondary);
                border-radius: 2px;

                &:focus-visible {
                  background-color: var(--background--card-hover);
                  outline: 1px solid var(--text-default);
                }
              }
              button {
                height: 2rem;
                margin-left: auto;
                padding: 0 1rem !important;
              }
            }
            &.editingPerson {
              padding: 0.25rem 0.5rem;
              border: 1px solid var(--accent);

              .dbPerson__item__menu {
                display: flex;
              }
            }
          }
        }
      }
    }
    .addNewPerson__wrapper {
      flex: 0 0 auto;
      align-self: flex-start;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px;
      padding: 0.5rem 1rem;

      background-color: var(--background--card);
      border-radius: 4px;

      .newPerson__control__wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        label {
          flex: 0 0 auto;
        }
        input {
          flex: 0 1 12rem;
          min-width: 0;
          margin-left: 0.5rem;
          padding: 3px 8px;
          background-color: var(--background--card-secondary);
          border-radius: 2px;

          &:focus-visible {
            background-color: var(--background--card-hover);
            outline: 1px solid var(--text-default);
          }
        }
      }
    }
    .personal__list__wrapper {
      flex: 6 1 0;
      display: flex;
      flex-direction: column;
      padding: 0.5rem 1rem;

      background-color: var(--background--card);
      border-radius: 4px;

      .personal__list__title {
        flex: 0 0 auto;
        padding: 0.5rem 0.5rem 0.75rem;
        font-size: 1.15rem;
      }
      .personal__list {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow-y: auto;

        .personal__item__wrapper {
          position: relative;
          flex: 0 0 auto;
          padding: 0.25rem 0.5rem;
          border-radius: 2px;

          input {
            padding: 2px 8px;
          }
          .removeParticipant__button {
            display: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0.5rem;

            color: var(--message-error);
            cursor: pointer;

            &:hover {
              font-weight: bold;
            }
          }
          &:hover {
            background-color: var(--background--card-hover);
            .removeParticipant__button {
              display: block;
            }
          }
        }
      }
      .personal__list__menu {
        flex: 0 0 auto;
        display: flex;
        justify-content: flex-end;
      }
    }

    .closeButton {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      height: 2.75rem;
      padding: 0 1rem !important;
      color: var(--text-default);
    }
  }
}
</style>
