<template>
  <div class="seminarsPage__wrapper">
    <search mode="seminars"></search>

    <div class="seminars__list__wrapper">
      <div class="seminars__list">
        <router-link
          v-for="(seminar, idx) in seminars"
          :key="seminar._id"
          :to="'/seminar_page/' + seminar._id"
        >
          <div :class="['seminar__item__wrapper', idx % 2 === 0 && 'isEven']">
            <div class="seminar__item title">
              {{ seminar["title"] }}
            </div>
            <div class="seminar__item sport">
              {{ seminar["sport"] }}

              <country-flag
                class="countryFlag"
                :country-code="getCountryCode(seminar['country'])"
                height="1rem"
              ></country-flag>
            </div>
            <div class="seminar__item region">
              {{ seminar["region"] }}
            </div>
            <div class="seminar__item date">
              {{ formatDate(seminar["date"]) }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { databaseUrl, uploadsFolderUrl } from "@/store/constants";
import { mdiAccount } from "@mdi/js";
import Search from "@/components/ui-components/search/index.vue";
import { getDisciplineCode } from "@/store/data/sports";
import { getRegionCode } from "@/store/data/russia-regions";
import { formatDate } from "@/utils/data-formaters";
import CountryFlag from "@/components/ui-components/country-flag.vue";
import { getCountryCode } from "@/store/data/countries";

export default {
  name: "seminarsPage",
  components: {
    CountryFlag,
    Search,
  },
  methods: {
    getCountryCode,
    formatDate,
    getRegionCode,
    getDisciplineCode,
    uploadsFolderUrl() {
      return uploadsFolderUrl;
    },
  },
  mounted() {
    axios.get(databaseUrl + "/seminars").then((response) => {
      if (response.status === 200) {
        this.seminars = [...response.data["seminars"]];
      }
    });
  },
  data() {
    return {
      athleteIcon: mdiAccount,
      seminars: [],
    };
  },
};
</script>

<style scoped lang="scss">
.seminarsPage__wrapper {
  flex: 1 1 0;
  display: flex;
  flex-wrap: nowrap;
  max-width: var(--desktop-small);
  width: 100%;
  overflow-y: auto;
  margin: 0 auto;
  padding: 2rem 2rem 8px;

  .seminars__list__wrapper {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;

    background-color: var(--background--card);
    backdrop-filter: blur(4px);
    border-radius: 4px;

    .seminars__list {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;

      .seminar__item__wrapper {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 8px;

        &.isEven {
          background-color: var(--background--card-secondary);
        }
        &:hover {
          background-color: var(--background--card-hover);
        }
        .seminar__item {
          display: flex;
          align-items: center;
          padding: 8px;
        }
        .seminar__item.title {
          font-size: 1.25rem;
          font-weight: bold;
        }
        .seminar__item.sport {
          font-size: 1.25rem;

          .countryFlag {
            margin-left: 0.5rem;
          }
        }
        .seminar__item.date {
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>
