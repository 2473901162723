<template>
  <router-link
    :key="event['event_id']"
    :class="[index % 2 > 0 && 'event_isOdd', dateMatch && 'calendarDate-match']"
    :to="{
      name: 'eventPage',
      params: { event_id: event['event_id'] },
    }"
    class="competition__link"
  >
    <div class="competitionImage__container">
      <img
        v-if="event['logo_image_url']"
        :src="uploadsFolderUrl + event['logo_image_url']"
        alt="Event Logo"
        class="competitionImage__image"
        loading="lazy"
      />

      <competition-image-filler-icon
        v-else
        class="imageFiller"
      ></competition-image-filler-icon>

      <country-flag
        v-if="event.region"
        :country-code="getCountryCode(event.country)"
        :is-region-flag="true"
        :region-code="getRegionCode(event.region)"
        class="competitionImage__flag"
        rounding="2px"
        width="1.5rem"
      ></country-flag>
      <country-flag
        v-else-if="getCountryCode(event.country)"
        :country-code="event['country_code']"
        class="competitionImage__flag"
        rounding="2px"
        width="1.5rem"
      ></country-flag>
    </div>

    <div class="competitionInfo__wrapper">
      <div class="competition__link__header">
        <div class="competition__link__header__title">
          {{ event["title"] }}
        </div>

        <div
          v-if="event['calendar_code']"
          class="competition__link__header__code"
        >
          {{ "ЕКП&nbsp;" + event["calendar_code"] }}
        </div>

        <div class="competition__link__header__sport">
          {{ event["sport"] }}
          <country-flag
            v-if="event.country"
            :country-code="getCountryCode(event.country)"
            class="countryFlag"
            width="1.4rem"
          ></country-flag>
        </div>
      </div>

      <div class="competition__link__body">
        <div class="competition__link__body__discipline">
          {{ event["discipline"] }}
        </div>
      </div>

      <div class="competition__link__footer">
        <div class="competition__link__footer__info__location">
          {{ event["region"] + ",&nbsp;" + event["location"] }}
        </div>
        <div class="competition__link__footer__info__date">
          {{ formatDate(event["start_at"]) }}
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import CountryFlag from "@/components/ui-components/country-flag.vue";
import CompetitionImageFillerIcon from "@/assets/svg/competitionImageFiller-icon.vue";
import { formatDate } from "@/utils/data-formaters";
import { getCountryCode } from "@/store/data/countries";
import { getRegionCode } from "@/store/data/russia-regions";
import { uploadsFolderUrl } from "@/store/constants";

export default {
  name: "competition-list-item",
  computed: {
    uploadsFolderUrl() {
      return uploadsFolderUrl;
    },
  },
  methods: {
    getRegionCode,
    getCountryCode,
    formatDate,
  },
  components: { CompetitionImageFillerIcon, CountryFlag },
  props: {
    event: { type: Object, required: true },
    index: Number,
    dateMatch: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.competition__link {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-wrap: nowrap;
  color: var(--text-default);
  cursor: pointer;

  /*noinspection CssUnusedSymbol*/
  &.event_isOdd {
    background-color: var(--background--card-secondary);
  }
  &.calendarDate-match {
    background-color: var(--background--card-hover);
    border-left: 4px solid var(--text-muted);
  }
  &:hover {
    background-color: var(--background--primary-hover);
  }
  .competitionImage__container {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    aspect-ratio: 1;
    padding: 0.5rem;

    .competitionImage__image {
      display: block;
      max-height: 100%;
      max-width: 100%;
    }
    .competitionImage__imageFiller__icon {
      height: 100%;
      width: 100%;
    }
    .competitionImage__flag {
      position: absolute;
      bottom: 0;
      right: -0.75rem;
    }

    @media screen and (max-width: 1440px) {
      width: 64px;
    }
    @media screen and (max-width: 1200px) {
      width: 50px;
    }
    @media screen and (max-width: 900px) {
      width: 46px;
    }
  }

  .competitionInfo__wrapper {
    position: relative;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;

    .competition__link__header {
      flex: 0 0 auto;
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      margin-bottom: 8px;

      .competition__link__header__title {
        flex: 1 1 auto;
        font-weight: bold;
        padding: 8px;
      }
      .competition__link__header__code {
        margin-left: auto;
        padding: 8px;
        color: var(--text-muted);
        font-size: 0.85rem;
        white-space: nowrap;

        @media screen and (max-width: 480px) {
          display: none;
        }
      }
      .competition__link__header__sport {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        font-weight: bold;
        padding: 0.5rem;
        margin-left: auto;
        color: var(--text-card-contrast);
        background-color: var(--text-default);
        border-radius: 0 2px 0 2px;
        transition: background-color 112ms;

        .countryFlag {
          margin-left: 8px;
          border: 1px solid var(--text-hovered);
        }
      }
    }
    .competition__link__body {
      padding: 0 8px;
    }

    .competition__link__footer {
      flex: 1 1 0;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      padding: 8px;
      font-size: 0.9rem;
      font-weight: 300;

      .competition__link__footer__info__location {
      }
      .competition__link__footer__info__date {
        margin-left: auto;
      }
    }
  }
}
</style>
