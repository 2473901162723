<template>
  <div class="mainView__wrapper">
    <div class="appHeader__wrapper">
      <div class="appHeader__content">
        <div class="header__logo__wrapper">
          <img
            class="header__logo__image"
            src="@/assets/logo/TIMINGWEBLOGO-WHITE.png"
            alt="tw-logo"
          />
        </div>

        <mobile-menu-button
          @toggle-menu="mobileMenuState = !mobileMenuState"
          :menu-state="mobileMenuState"
        ></mobile-menu-button>
        <nav-bar></nav-bar>

        <router-link
          to="/auth"
          custom
          :class="['login__link', isLoggedIn && 'logged_in']"
        >
          <v-icon
            class="login__link__button__icon"
            color="var(--text-contrast)"
            size="28px"
          >
            {{ icons.mdiAccount }}
          </v-icon>
          <span class="login__link__button__text">
            {{ isLoggedIn ? userName : "Войти" }}
          </span>
        </router-link>

        <theme-switch></theme-switch>
      </div>

      <menu-mobile
        @menu-navigated="mobileMenuState = false"
        :menu-state="mobileMenuState"
      ></menu-mobile>
    </div>

    <div class="app_main_container">
      <router-view></router-view>
    </div>

    <div class="appFooter__wrapper">
      <div class="appFooter__content">
        <span>Created by TimingWeb &copy;</span>
        <span class="projectDate">{{ new Date().getFullYear() }}</span>

        <img
          class="companyLogo__image"
          src="@/assets/logo/SPORTTECHLOGO-WHITE.png"
          alt="SportTech-Logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mdiAccount } from "@mdi/js";
import NavBar from "@/components/navigation/menu-desktop.vue";
import ThemeSwitch from "@/components/ui-components/theme-switch.vue";
import { checkUserTheme } from "@/utils/check-theme";
import { mapGetters } from "vuex";
import MenuMobile from "@/components/navigation/menu-mobile.vue";
import MobileMenuButton from "@/components/navigation/mobile-menu-button.vue";

export default {
  name: "Home",
  methods: {
    checkUserTheme,
  },
  components: { MobileMenuButton, MenuMobile, ThemeSwitch, NavBar },
  data() {
    return {
      icons: { mdiAccount },
      mobileMenuState: false,
    };
  },
  computed: {
    ...mapGetters("authorization", {
      userName: "getUserName",
      isLoggedIn: "isAuthorized",
    }),
  },
};
</script>
<style lang="scss">
.mainView__wrapper {
  position: relative;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;

  .appHeader__wrapper {
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
    padding: 0 2rem;
    background-color: var(--background--secondary);

    .appHeader__content {
      flex: 0 0 auto;
      display: flex;
      max-width: var(--desktop-small);
      margin: 0 auto;

      .header__logo__wrapper {
        display: flex;
        place-items: center;
        margin: 8px 0;
        padding: 0.25rem 2rem 0.25rem 0;
        border-right: 2px solid var(--text-contrast);

        @media screen and (max-width: 920px) {
          border-right: 0;
        }
        @media screen and (max-width: 480px) {
          display: none;
        }
      }
      .header__logo__image {
        max-height: 32px;
      }
    }
  }
}

.app_header_title {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--background--secondary);

  background-image: url("./../assets/logo/TIMINGWEBLOGO-WHITE.png");
  background-position: left;
  background-size: auto 70%;
  background-repeat: no-repeat;
}

.login__link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: auto 0 auto auto;
  padding: 4px 12px;

  color: var(--text-contrast);
  border-radius: 99px;
  font-size: 16px;
  font-weight: bold;

  .login__link__button__text {
    margin-left: 4px;
  }

  &.logged_in {
    background-color: var(--text-contrast);
    color: var(--background--secondary);
    transition: background-color 92ms, color 92ms;
  }
  &.logged_in svg {
    fill: var(--background--secondary);
    transition: fill 92ms;
  }

  &:hover,
  &:hover svg {
    color: var(--text-contrast-hovered);
    fill: var(--text-contrast-hovered);
  }
}

.app_main_container {
  position: relative;
  isolation: isolate;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: var(--background--primary);

  &::before {
    position: absolute;
    z-index: -1;
    content: "";
    height: 100%;
    width: 100%;
    opacity: 0.5;
    backdrop-filter: blur(8px);
    //background-image: url("./../assets/logo/TIMINGWEBLOGO-BLACK.png");
    background-repeat: no-repeat;
    background-position: center;
  }
  &::after {
    position: absolute;
    z-index: -1;
    content: "";
    height: 100%;
    width: 100%;
    backdrop-filter: blur(2px);
  }
}
.theme-dark .app_main_container::before {
  //background-image: url("./../assets/logo/TIMINGWEBLOGO-WHITE.png");
  background-image: linear-gradient(
    to bottom,
    var(--background--primary),
    var(--background--secondary)
  );
}

.appFooter__wrapper {
  flex: 0 1 auto;
  padding: 0.75rem 2rem;
  background-color: var(--background--secondary);
}
.appFooter__content {
  display: flex;
  align-items: flex-end;
  max-width: var(--desktop-small);
  width: 100%;
  margin: 0 auto;

  font-size: 0.9rem;
  font-weight: bold;
  color: var(--text-contrast);
}
.projectDate {
  margin-left: 8px;
}
.companyLogo__image {
  margin-left: auto;
  max-height: 1.4rem;
}
</style>
