var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personPhoto__wrapper"},[(_vm.person.photo_url)?_c('img',{staticClass:"athleteInfo__image",attrs:{"src":_vm.uploadsFolderUrl + _vm.person.photo_url,"alt":"img","loading":"lazy"}}):_c('athlete-photo-filler-icon',{staticClass:"athletePhotoFiller__icon",attrs:{"gender":_vm.person.gender}}),(_vm.person.regions && _vm.person.regions.length)?_c('div',{staticClass:"personRegionFlags__wrapper",style:(_vm.person.regions.length > 1 && {
        transform: `translate(-${2 * _vm.person.regions.length}px, -${
          _vm.person.regions.length * 4
        }px)`,
      })},_vm._l((_vm.person.regions),function(region,idx){return _c('country-flag',{key:idx,staticClass:"personRegionFlag",style:({
        zIndex: -idx,
        transform: `translate(${4 * idx}px, ${6 * idx}px)`,
      }),attrs:{"is-region-flag":"true","country-code":_vm.getCountryCode(_vm.person.country),"region-code":_vm.getRegionCode(region),"width":"calc(8px + 1rem)","rounding":"2px"}})}),1):(_vm.person.region)?_c('div',{staticClass:"personRegionFlags__wrapper"},[_c('country-flag',{staticClass:"personRegionFlag",attrs:{"is-region-flag":"true","country-code":_vm.getCountryCode(_vm.person.country),"region-code":_vm.getRegionCode(_vm.person.region),"width":"calc(8px + 1rem)","rounding":"2px"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }