<template>
  <div class="navBar__wrapper">
    <router-link
      v-for="menu_item in appMenu()"
      :key="menu_item.title"
      v-slot="{ navigate, isActive }"
      custom
      :to="{ name: menu_item.link }"
    >
      <button
        @click="navigate"
        :class="['navBar__button', isActive && 'isActive']"
      >
        {{ menu_item.title }}
      </button>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "navBar",
  methods: {
    appMenu() {
      return this.userData.role === "admin"
        ? this.menu
        : this.menu.filter((menuItem) => !menuItem.adminOnly);
    },
  },
  computed: {
    ...mapGetters("authorization", {
      userData: "getUserData",
    }),
    ...mapGetters("menu", {
      menu: "getMenu",
    }),
  },
};
</script>

<style scoped>
.navBar__wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 920px) {
  .navBar__wrapper {
    display: none;
  }
}

.navBar__button {
  flex: 0 1 auto;
  position: relative;
  display: inline-block;
  align-items: center;
  padding: 3px 6px;
  margin: 1rem;
  text-decoration: none;
  color: var(--text-contrast);
  font-weight: bold;

  transition: color 96ms, background-color 96ms;
}
.navBar__button:hover {
  color: var(--text-contrast-hovered);
}
.navBar__button::after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 0;
  height: 2px;
  background: var(--text-contrast);
  transition: width 92ms ease-out;
}
/*noinspection CssUnusedSymbol*/
.navBar__button.isActive::after {
  width: 100%;
}
</style>
