<template>
  <div>
    <canvas id="mountains"></canvas>
  </div>
</template>
<script>
import * as rive from "@rive-app/canvas";

export default {
  name: "bg-mountains",

  mounted() {
    const canvas = document.getElementById("mountains");

    const bgLayout = new rive.Layout({
      fit: rive.Fit.Cover,
      alignment: rive.Alignment.CenterLeft,
    });
    const riveBg = new rive.Rive({
      canvas: canvas,
      src: "/ffr_background.riv",
      layout: bgLayout,
      autoplay: true,
      onLoad: () => {
        riveBg.resizeDrawingSurfaceToCanvas();
      },
    });
  },
};
</script>

<style scoped lang="scss">
canvas {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top left;
}
</style>
