export const countries = [
  {
    country_name: "Россия",
    country_code: "RU",
  },
  {
    country_name: "Беларусь",
    country_code: "BY",
  },
  {
    country_name: "Казахстан",
    country_code: "KZ",
  },
  {
    country_name: "Австралия",
    country_code: "AU",
  },
  {
    country_name: "Австрия",
    country_code: "AT",
  },
  {
    country_name: "Азербайджан",
    country_code: "AZ",
  },
  {
    country_name: "Аландские острова",
    country_code: "AX",
  },
  {
    country_name: "Албания",
    country_code: "AL",
  },
  {
    country_name: "Алжир",
    country_code: "DZ",
  },
  {
    country_name: "Виргинские Острова (США)",
    country_code: "VI",
  },
  {
    country_name: "Американское Самоа",
    country_code: "AS",
  },
  {
    country_name: "Ангилья",
    country_code: "AI",
  },
  {
    country_name: "Ангола",
    country_code: "AO",
  },
  {
    country_name: "Андорра",
    country_code: "AD",
  },
  {
    country_name: "Антарктида",
    country_code: "AQ",
  },
  {
    country_name: "Антигуа и Барбуда",
    country_code: "AG",
  },
  {
    country_name: "Аргентина",
    country_code: "AR",
  },
  {
    country_name: "Армения",
    country_code: "AM",
  },
  {
    country_name: "Аруба",
    country_code: "AW",
  },
  {
    country_name: "Афганистан",
    country_code: "AF",
  },
  {
    country_name: "Багамы",
    country_code: "BS",
  },
  {
    country_name: "Бангладеш",
    country_code: "BD",
  },
  {
    country_name: "Барбадос",
    country_code: "BB",
  },
  {
    country_name: "Бахрейн",
    country_code: "BH",
  },
  {
    country_name: "Белиз",
    country_code: "BZ",
  },
  {
    country_name: "Бельгия",
    country_code: "BE",
  },
  {
    country_name: "Бенин",
    country_code: "BJ",
  },
  {
    country_name: "Бермуды",
    country_code: "BM",
  },
  {
    country_name: "Болгария",
    country_code: "BG",
  },
  {
    country_name: "Боливия",
    country_code: "BO",
  },
  {
    country_name: "Бонэйр, Синт-Эстатиус и Саба",
    country_code: "BQ",
  },
  {
    country_name: "Босния и Герцеговина",
    country_code: "BA",
  },
  {
    country_name: "Ботсвана",
    country_code: "BW",
  },
  {
    country_name: "Бразилия",
    country_code: "BR",
  },
  {
    country_name: "Британская территория в Индийском океане",
    country_code: "IO",
  },
  {
    country_name: "Виргинские Острова (Великобритания)",
    country_code: "VG",
  },
  {
    country_name: "Бруней",
    country_code: "BN",
  },
  {
    country_name: "Буркина-Фасо",
    country_code: "BF",
  },
  {
    country_name: "Бурунди",
    country_code: "BI",
  },
  {
    country_name: "Бутан",
    country_code: "BT",
  },
  {
    country_name: "Вануату",
    country_code: "VU",
  },
  {
    country_name: "Ватикан",
    country_code: "VA",
  },
  {
    country_name: "Великобритания",
    country_code: "GB",
  },
  {
    country_name: "Венгрия",
    country_code: "HU",
  },
  {
    country_name: "Венесуэла",
    country_code: "VE",
  },
  {
    country_name: "Внешние малые острова (США)",
    country_code: "UM",
  },
  {
    country_name: "Восточный Тимор",
    country_code: "TL",
  },
  {
    country_name: "Вьетнам",
    country_code: "VN",
  },
  {
    country_name: "Габон",
    country_code: "GA",
  },
  {
    country_name: "Гаити",
    country_code: "HT",
  },
  {
    country_name: "Гайана",
    country_code: "GY",
  },
  {
    country_name: "Гамбия",
    country_code: "GM",
  },
  {
    country_name: "Гана",
    country_code: "GH",
  },
  {
    country_name: "Гваделупа",
    country_code: "GP",
  },
  {
    country_name: "Гватемала",
    country_code: "GT",
  },
  {
    country_name: "Гвиана",
    country_code: "GF",
  },
  {
    country_name: "Гвинея",
    country_code: "GN",
  },
  {
    country_name: "Гвинея-Бисау",
    country_code: "GW",
  },
  {
    country_name: "Германия",
    country_code: "DE",
  },
  {
    country_name: "Гернси",
    country_code: "GG",
  },
  {
    country_name: "Гибралтар",
    country_code: "GI",
  },
  {
    country_name: "Гондурас",
    country_code: "HN",
  },
  {
    country_name: "Гонконг",
    country_code: "HK",
  },
  {
    country_name: "Гренада",
    country_code: "GD",
  },
  {
    country_name: "Гренландия",
    country_code: "GL",
  },
  {
    country_name: "Греция",
    country_code: "GR",
  },
  {
    country_name: "Грузия",
    country_code: "GE",
  },
  {
    country_name: "Гуам",
    country_code: "GU",
  },
  {
    country_name: "Дания",
    country_code: "DK",
  },
  {
    country_name: "Джерси",
    country_code: "JE",
  },
  {
    country_name: "Джибути",
    country_code: "DJ",
  },
  {
    country_name: "Доминика",
    country_code: "DM",
  },
  {
    country_name: "Доминиканская Республика",
    country_code: "DO",
  },
  {
    country_name: "Демократическая Республика Конго",
    country_code: "CD",
  },
  {
    country_name: "Европейский союз",
    country_code: "EU",
  },
  {
    country_name: "Египет",
    country_code: "EG",
  },
  {
    country_name: "Замбия",
    country_code: "ZM",
  },
  {
    country_name: "САДР",
    country_code: "EH",
  },
  {
    country_name: "Зимбабве",
    country_code: "ZW",
  },
  {
    country_name: "Израиль",
    country_code: "IL",
  },
  {
    country_name: "Индия",
    country_code: "IN",
  },
  {
    country_name: "Индонезия",
    country_code: "ID",
  },
  {
    country_name: "Иордания",
    country_code: "JO",
  },
  {
    country_name: "Ирак",
    country_code: "IQ",
  },
  {
    country_name: "Иран",
    country_code: "IR",
  },
  {
    country_name: "Ирландия",
    country_code: "IE",
  },
  {
    country_name: "Исландия",
    country_code: "IS",
  },
  {
    country_name: "Испания",
    country_code: "ES",
  },
  {
    country_name: "Италия",
    country_code: "IT",
  },
  {
    country_name: "Йемен",
    country_code: "YE",
  },
  {
    country_name: "Кабо-Верде",
    country_code: "CV",
  },
  {
    country_name: "Острова Кайман",
    country_code: "KY",
  },
  {
    country_name: "Камбоджа",
    country_code: "KH",
  },
  {
    country_name: "Камерун",
    country_code: "CM",
  },
  {
    country_name: "Канада",
    country_code: "CA",
  },
  {
    country_name: "Катар",
    country_code: "QA",
  },
  {
    country_name: "Кения",
    country_code: "KE",
  },
  {
    country_name: "Кипр",
    country_code: "CY",
  },
  {
    country_name: "Киргизия",
    country_code: "KG",
  },
  {
    country_name: "Кирибати",
    country_code: "KI",
  },
  {
    country_name: "Китайская Республика",
    country_code: "TW",
  },
  {
    country_name: "КНДР (Корейская Народно-Демократическая Республика)",
    country_code: "KP",
  },
  {
    country_name: "КНР (Китайская Народная Республика)",
    country_code: "CN",
  },
  {
    country_name: "Кокосовые острова",
    country_code: "CC",
  },
  {
    country_name: "Колумбия",
    country_code: "CO",
  },
  {
    country_name: "Коморы",
    country_code: "KM",
  },
  {
    country_name: "Коста-Рика",
    country_code: "CR",
  },
  {
    country_name: "Кот-д’Ивуар",
    country_code: "CI",
  },
  {
    country_name: "Куба",
    country_code: "CU",
  },
  {
    country_name: "Кувейт",
    country_code: "KW",
  },
  {
    country_name: "Кюрасао",
    country_code: "CW",
  },
  {
    country_name: "Лаос",
    country_code: "LA",
  },
  {
    country_name: "Латвия",
    country_code: "LV",
  },
  {
    country_name: "Лесото",
    country_code: "LS",
  },
  {
    country_name: "Либерия",
    country_code: "LR",
  },
  {
    country_name: "Ливан",
    country_code: "LB",
  },
  {
    country_name: "Ливия",
    country_code: "LY",
  },
  {
    country_name: "Литва",
    country_code: "LT",
  },
  {
    country_name: "Лихтенштейн",
    country_code: "LI",
  },
  {
    country_name: "Люксембург",
    country_code: "LU",
  },
  {
    country_name: "Маврикий",
    country_code: "MU",
  },
  {
    country_name: "Мавритания",
    country_code: "MR",
  },
  {
    country_name: "Мадагаскар",
    country_code: "MG",
  },
  {
    country_name: "Майотта",
    country_code: "YT",
  },
  {
    country_name: "Макао",
    country_code: "MO",
  },
  {
    country_name: "Македония",
    country_code: "MK",
  },
  {
    country_name: "Малави",
    country_code: "MW",
  },
  {
    country_name: "Малайзия",
    country_code: "MY",
  },
  {
    country_name: "Мали",
    country_code: "ML",
  },
  {
    country_name: "Мальдивы",
    country_code: "MV",
  },
  {
    country_name: "Мальта",
    country_code: "MT",
  },
  {
    country_name: "Марокко",
    country_code: "MA",
  },
  {
    country_name: "Мартиника",
    country_code: "MQ",
  },
  {
    country_name: "Маршалловы Острова",
    country_code: "MH",
  },
  {
    country_name: "Мексика",
    country_code: "MX",
  },
  {
    country_name: "Микронезия",
    country_code: "FM",
  },
  {
    country_name: "Мозамбик",
    country_code: "MZ",
  },
  {
    country_name: "Молдавия",
    country_code: "MD",
  },
  {
    country_name: "Монако",
    country_code: "MC",
  },
  {
    country_name: "Монголия",
    country_code: "MN",
  },
  {
    country_name: "Монтсеррат",
    country_code: "MS",
  },
  {
    country_name: "Мьянма",
    country_code: "MM",
  },
  {
    country_name: "Намибия",
    country_code: "NA",
  },
  {
    country_name: "Науру",
    country_code: "NR",
  },
  {
    country_name: "Непал",
    country_code: "NP",
  },
  {
    country_name: "Нигер",
    country_code: "NE",
  },
  {
    country_name: "Нигерия",
    country_code: "NG",
  },
  {
    country_name: "Нидерланды",
    country_code: "NL",
  },
  {
    country_name: "Никарагуа",
    country_code: "NI",
  },
  {
    country_name: "Ниуэ",
    country_code: "NU",
  },
  {
    country_name: "Новая Зеландия",
    country_code: "NZ",
  },
  {
    country_name: "Новая Каледония",
    country_code: "NC",
  },
  {
    country_name: "Норвегия",
    country_code: "NO",
  },
  {
    country_name: "ОАЭ",
    country_code: "AE",
  },
  {
    country_name: "Оман",
    country_code: "OM",
  },
  {
    country_name: "Остров Буве",
    country_code: "BV",
  },
  {
    country_name: "Остров Мэн",
    country_code: "IM",
  },
  {
    country_name: "Острова Кука",
    country_code: "CK",
  },
  {
    country_name: "Остров Норфолк",
    country_code: "NF",
  },
  {
    country_name: "Остров Рождества",
    country_code: "CX",
  },
  {
    country_name: "Острова Питкэрн",
    country_code: "PN",
  },
  {
    country_name: "Острова Святой Елены, Вознесения и Тристан-да-Кунья",
    country_code: "SH",
  },
  {
    country_name: "Пакистан",
    country_code: "PK",
  },
  {
    country_name: "Палау",
    country_code: "PW",
  },
  {
    country_name: "Государство Палестина",
    country_code: "PS",
  },
  {
    country_name: "Панама",
    country_code: "PA",
  },
  {
    country_name: "Папуа — Новая Гвинея",
    country_code: "PG",
  },
  {
    country_name: "Парагвай",
    country_code: "PY",
  },
  {
    country_name: "Перу",
    country_code: "PE",
  },
  {
    country_name: "Польша",
    country_code: "PL",
  },
  {
    country_name: "Португалия",
    country_code: "PT",
  },
  {
    country_name: "Пуэрто-Рико",
    country_code: "PR",
  },
  {
    country_name: "Республика Конго",
    country_code: "CG",
  },
  {
    country_name: "Республика Корея",
    country_code: "KR",
  },
  {
    country_name: "Реюньон",
    country_code: "RE",
  },
  {
    country_name: "Руанда",
    country_code: "RW",
  },
  {
    country_name: "Румыния",
    country_code: "RO",
  },
  {
    country_name: "Сальвадор",
    country_code: "SV",
  },
  {
    country_name: "Самоа",
    country_code: "WS",
  },
  {
    country_name: "Сан-Марино",
    country_code: "SM",
  },
  {
    country_name: "Сан-Томе и Принсипи",
    country_code: "ST",
  },
  {
    country_name: "Саудовская Аравия",
    country_code: "SA",
  },
  {
    country_name: "Свазиленд",
    country_code: "SZ",
  },
  {
    country_name: "Северные Марианские Острова",
    country_code: "MP",
  },
  {
    country_name: "Сейшельские Острова",
    country_code: "SC",
  },
  {
    country_name: "Сен-Бартелеми",
    country_code: "BL",
  },
  {
    country_name: "Сен-Мартен",
    country_code: "MF",
  },
  {
    country_name: "Сен-Пьер и Микелон",
    country_code: "PM",
  },
  {
    country_name: "Сенегал",
    country_code: "SN",
  },
  {
    country_name: "Сент-Винсент и Гренадины",
    country_code: "VC",
  },
  {
    country_name: "Сент-Китс и Невис",
    country_code: "KN",
  },
  {
    country_name: "Сент-Люсия",
    country_code: "LC",
  },
  {
    country_name: "Сербия",
    country_code: "RS",
  },
  {
    country_name: "Сингапур",
    country_code: "SG",
  },
  {
    country_name: "Синт-Мартен",
    country_code: "SX",
  },
  {
    country_name: "Сирия",
    country_code: "SY",
  },
  {
    country_name: "Словакия",
    country_code: "SK",
  },
  {
    country_name: "Словения",
    country_code: "SI",
  },
  {
    country_name: "Соломоновы Острова",
    country_code: "SB",
  },
  {
    country_name: "Сомали",
    country_code: "SO",
  },
  {
    country_name: "Судан",
    country_code: "SD",
  },
  {
    country_name: "Суринам",
    country_code: "SR",
  },
  {
    country_name: "США",
    country_code: "US",
  },
  {
    country_name: "Сьерра-Леоне",
    country_code: "SL",
  },
  {
    country_name: "Таджикистан",
    country_code: "TJ",
  },
  {
    country_name: "Таиланд",
    country_code: "TH",
  },
  {
    country_name: "Танзания",
    country_code: "TZ",
  },
  {
    country_name: "Тёркс и Кайкос",
    country_code: "TC",
  },
  {
    country_name: "Того",
    country_code: "TG",
  },
  {
    country_name: "Токелау",
    country_code: "TK",
  },
  {
    country_name: "Тонга",
    country_code: "TO",
  },
  {
    country_name: "Тринидад и Тобаго",
    country_code: "TT",
  },
  {
    country_name: "Тувалу",
    country_code: "TV",
  },
  {
    country_name: "Тунис",
    country_code: "TN",
  },
  {
    country_name: "Туркмения",
    country_code: "TM",
  },
  {
    country_name: "Турция",
    country_code: "TR",
  },
  {
    country_name: "Уганда",
    country_code: "UG",
  },
  {
    country_name: "Узбекистан",
    country_code: "UZ",
  },
  {
    country_name: "Украина",
    country_code: "UA",
  },
  {
    country_name: "Уоллис и Футуна",
    country_code: "WF",
  },
  {
    country_name: "Уругвай",
    country_code: "UY",
  },
  {
    country_name: "Фареры",
    country_code: "FO",
  },
  {
    country_name: "Фиджи",
    country_code: "FJ",
  },
  {
    country_name: "Филиппины",
    country_code: "PH",
  },
  {
    country_name: "Финляндия",
    country_code: "FI",
  },
  {
    country_name: "Фолклендские острова",
    country_code: "FK",
  },
  {
    country_name: "Франция",
    country_code: "FR",
  },
  {
    country_name: "Французская Полинезия",
    country_code: "PF",
  },
  {
    country_name: "Французские Южные и Антарктические Территории",
    country_code: "TF",
  },
  {
    country_name: "Херд и Макдональд",
    country_code: "HM",
  },
  {
    country_name: "Хорватия",
    country_code: "HR",
  },
  {
    country_name: "ЦАР",
    country_code: "CF",
  },
  {
    country_name: "Чад",
    country_code: "TD",
  },
  {
    country_name: "Черногория",
    country_code: "ME",
  },
  {
    country_name: "Чехия",
    country_code: "CZ",
  },
  {
    country_name: "Чили",
    country_code: "CL",
  },
  {
    country_name: "Швейцария",
    country_code: "CH",
  },
  {
    country_name: "Швеция",
    country_code: "SE",
  },
  {
    country_name: "Шпицберген и Ян-Майен",
    country_code: "SJ",
  },
  {
    country_name: "Шри-Ланка",
    country_code: "LK",
  },
  {
    country_name: "Эквадор",
    country_code: "EC",
  },
  {
    country_name: "Экваториальная Гвинея",
    country_code: "GQ",
  },
  {
    country_name: "Эритрея",
    country_code: "ER",
  },
  {
    country_name: "Эстония",
    country_code: "EE",
  },
  {
    country_name: "Эфиопия",
    country_code: "ET",
  },
  {
    country_name: "ЮАР",
    country_code: "ZA",
  },
  {
    country_name: "Южная Георгия и Южные Сандвичевы Острова",
    country_code: "GS",
  },
  {
    country_name: "Южный Судан",
    country_code: "SS",
  },
  {
    country_name: "Ямайка",
    country_code: "JM",
  },
  {
    country_name: "Япония",
    country_code: "JP",
  },
];

export const getCountryCode = (countryName) => {
  const country = countries.find(
    (country) => country.country_name === countryName
  );
  if (!country) return "-";

  return country.country_code;
};
