import axios from "axios";
import { databaseUrl } from "@/store/constants";

export default {
  namespaced: true,
  state: {
    events: [],
  },
  getters: {
    events: (state) => state.events,
  },
  mutations: {
    setEvents: (state, events) => {
      state.events = events;
    },
  },
  actions: {
    SET_EVENTS: ({ commit }, payload) => {
      commit("setEvents", payload);
    },
    LOAD_EVENTS: async (store) => {
      try {
        const response = await axios.get(databaseUrl + "/events");
        if (response.status === 200) {
          store.commit("setEvents", response.data.events);
        }
      } catch (err) {
        if (err) {
          setTimeout(() => store.dispatch("LOAD_EVENTS"), 2000);
        }
      }
    },
  },
};
