<template>
  <select
    @input="$emit('input', $event.target.value)"
    id="sport"
    name="sport"
    class="searchInput__control"
  >
    <option :data-cancel-option="!!elemValue" value="">
      {{ elemValue ? "Очистить" : "Выберите вид спорта" }}
    </option>
    <option v-for="sport in sportsList" :key="sport" :value="sport">
      {{ sport }}
    </option>
  </select>
</template>

<script>
import { sports } from "@/store/data/sports";

export default {
  name: "sport-input",
  computed: {
    sportsList() {
      return sports.map((sport) => sport.name_rus);
    },
    elemValue() {
      return this.$attrs.value;
    },
  },
};
</script>

<style scoped>
@import "search-input-style.css";
</style>
