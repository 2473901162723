<template>
  <div class="themeSwitch__wrapper">
    <v-btn @click.prevent="toggleTheme()" color="var(--text-contrast)" icon>
      <v-icon v-if="appTheme === 'dark'">
        {{ themeLightIcon }}
      </v-icon>
      <v-icon v-else>
        {{ themeDarkIcon }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mdiBrightness2, mdiBrightness7 } from "@mdi/js";
import { checkUserTheme } from "@/utils/check-theme";

export default {
  name: "theme-switch",
  mounted() {
    this.$nextTick(() => {
      this.appTheme = checkUserTheme();
    });
  },
  methods: {
    toggleTheme() {
      if (this.appTheme === "dark") {
        document.getElementById("app").classList.remove("theme-dark");
        localStorage.setItem("app-theme", "light");
        this.appTheme = "light";
      } else {
        document.getElementById("app").classList.add("theme-dark");
        localStorage.setItem("app-theme", "dark");
        this.appTheme = "dark";
      }
    },
  },
  data() {
    return {
      appTheme: "",
      themeDarkIcon: mdiBrightness2,
      themeLightIcon: mdiBrightness7,
    };
  },
};
</script>

<style scoped>
.themeSwitch__wrapper {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
</style>
