var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.competition)?_c('div',{staticClass:"resultsTable__wrapper"},[(_vm.competition['races'].length > 0)?_c('div',{staticClass:"raceSelect__wrapper"},[_c('div',{staticClass:"raceFilters__group startListFilters__wrapper"},_vm._l((_vm.competition['races']),function(race){return _c('button',{key:`SLFilter_${race['race_id']}`,class:[
          'raceSelect__button',
          'startList',
          _vm.raceFilter.type === 'start-list' &&
            _vm.raceFilter.target === race['race_id'] &&
            'isSelectedFilter',
        ],on:{"click":function($event){return _vm.setResultsFilter(`start-list:${race['race_id']}`)}}},[_vm._v(" Старт-лист "+_vm._s(race.title)+" ")])}),0),_c('div',{staticClass:"raceFilters__group raceResultsFilters__wrapper"},_vm._l((_vm.competition['races']),function(race){return _c('button',{key:`RFilter_${race._id}`,class:[
          'raceSelect__button',
          'raceResults',
          _vm.raceFilter.type === 'race-results' &&
            _vm.raceFilter.target === race['race_id'] &&
            'isSelectedFilter',
        ],on:{"click":function($event){return _vm.setResultsFilter(`race-results:${race['race_id']}`)}}},[_vm._v(" "+_vm._s(race.title)+" ")])}),0),_c('div',{staticClass:"raceFilters__group overallResultFilter__wrapper"},[_c('button',{class:[
          'raceSelect__button',
          'overallResult',
          _vm.raceFilter.type === 'overall' && 'isSelectedFilter',
        ],on:{"click":function($event){return _vm.setResultsFilter('overall')}}},[_vm._v(" Результаты ")])])]):_vm._e(),_c('div',{staticClass:"resultsTable__header"},[_c('div',{staticClass:"resultsTable__tableValue",attrs:{"data-header-value":"rank"}},[_vm._v(" "+_vm._s(_vm.raceFilter.type === "start-list" ? "Ст. №" : "Место")+" ")]),_c('div',{staticClass:"resultsTable__tableValue",attrs:{"data-header-value":"bib"}},[_vm._v("Н/Н")]),_c('div',{staticClass:"resultsTable__tableValue",attrs:{"data-header-value":"name"}},[_vm._v(" Фамилия, имя ")]),_vm._l((_vm.competition['races']),function(race){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.raceFilter.type === 'overall'),expression:"raceFilter.type === 'overall'"}],key:`rr_${race._id}`,staticClass:"resultsTable__tableValue",attrs:{"data-header-value":"raceResult"}},[_vm._v(" "+_vm._s(race.title)+" ")])}),(_vm.raceFilter.type !== 'start-list')?_c('div',{staticClass:"resultsTable__tableValue",attrs:{"data-header-value":"overallResult"}},[_vm._v(" Рез-т ")]):_vm._e()],2),_c('div',{staticClass:"resultsSheet__body"},_vm._l((_vm.getSheetContent),function(competitor,comp_idx){return _c('div',{key:comp_idx,class:[
        'resultsSheet__competitorResult',
        comp_idx % 2 > 0 && 'isOddCompetitor',
      ]},[_c('div',{staticClass:"resultsTable__tableValue",attrs:{"data-header-value":"rank"}},[_vm._v(" "+_vm._s(comp_idx + 1)+" ")]),_c('div',{staticClass:"resultsTable__tableValue",attrs:{"data-header-value":"bib"}},[_vm._v(" "+_vm._s(competitor["bib"])+" ")]),(competitor['region_code'])?_c('div',{staticClass:"athleteFlag__wrapper"},[_c('country-flag',{attrs:{"is-region-flag":true,"country-code":competitor['country_code'],"region-code":competitor['region_code'],"height":"1rem"}})],1):_vm._e(),_c('div',{staticClass:"resultsTable__tableValue",attrs:{"data-header-value":"name"},on:{"click":function($event){return _vm.navigateToAthletePage(competitor['rus_code'])}}},[_c('span',[_vm._v(_vm._s(`${competitor["lastname"].toUpperCase()} ${competitor["name"]}`))])]),(_vm.raceFilter.type === 'race-results')?_c('div',{key:`raceRes_${competitor['local_id']}`,staticClass:"resultsTable__tableValue",attrs:{"data-header-value":"raceResult"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn()),expression:"isLoggedIn()"}],staticClass:"showMarks__button",attrs:{"color":"var(--text-default)","small":"","text":""},on:{"click":function($event){return _vm.selectMarksToShow(competitor['local_id'])}}},[(_vm.marksFilter === competitor['local_id'])?_c('v-icon',{attrs:{"size":"1.2rem"}},[_vm._v(" "+_vm._s(_vm.icons.closeMarksIcon)+" ")]):_c('v-icon',{attrs:{"size":"1.2rem"}},[_vm._v(_vm._s(_vm.icons.showMarksButtonIcon))])],1),(
            _vm.raceFilter.type === 'race-results' &&
            _vm.marksFilter === competitor['local_id']
          )?_c('div',{staticClass:"competitorRaceMarks__wrapper"},_vm._l((competitor.result.marks),function(mark){return _c('div',{key:mark['judge'],staticClass:"competitorRaceMarks__item"},[_vm._v(" "+_vm._s(`С${mark["judge_id"]}: `)+" "),_c('span',{staticClass:"competitorRaceMarks__item__value"},[_vm._v(" "+_vm._s(mark.value)+" ")])])}),0):_vm._e(),_vm._v(" "+_vm._s(competitor.result.value)+" ")],1):_vm._e(),_vm._l((competitor.raceResults),function(raceResult,idx){return _c('div',{key:`raceRes_${idx}`,staticClass:"resultsTable__tableValue",attrs:{"data-header-value":"raceResult"}},[(_vm.raceFilter.type === 'overall')?_c('span',[_vm._v(" "+_vm._s(raceResult ? raceResult.value : "")+" ")]):_vm._e()])}),(_vm.raceFilter.type === 'overall')?_c('div',{staticClass:"resultsTable__tableValue",attrs:{"data-header-value":"overallResult"}},[_vm._v(" "+_vm._s(competitor.totalResult.value)+" ")]):_vm._e()],2)}),0),_c('div',{staticClass:"competitorOnDistance__wrapper"},[_c('div',{staticClass:"competitorOnDistance__title"},[_vm._v("На дистанции")]),(_vm.onDistance)?_c('div',{staticClass:"competitorOnDistance__body"},[_vm._v(" "+_vm._s(`${_vm.onDistance["bib"]} ${_vm.onDistance["name"]} ${_vm.onDistance["lastname"]}`)+" ")]):_c('div',{staticClass:"competitorOnDistance__empty"},[_vm._v("Ожидание...")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }